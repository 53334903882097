<template>
  <div class="shenshi-wrapper">
    <h2 class="shenshi-title">隐私声明</h2>
    <p class="shenshi-p">本公众号的部分内容在使用中，需要注册及提供一定的个人信息（如微信登录信息等）。北京甚智医学科技有限公司非常重视保护访问/使用者的隐私信息。如果您主动通过本公众号传递给本公司某些个人信息，即表明您同意北京甚智医学科技有限公司使用和处理您提供的信息，相关信息将会被适当处理（包含合理的内部使用），且不会用于您提供信息时明确的相关目的之外的其他用途。北京甚智医学科技有限公司会采取必要的措施，保护信息的安全。如果您需要了解其他更多关于隐私政策的相关信息，请您访问并阅读北京甚智医学科技有限公司的用户数据保护声明（www.ai-ckd.com），或者直接联系我们寻求帮助。</p>
  </div>
</template>

<script>
export default {
name: 'YinSi'
}
</script>

<style>

</style>